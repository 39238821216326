<template src="./templates/questionsBenefits.html"></template>
<script>

import './styles.scss';
import '@vueform/multiselect/themes/default.css';
import {
    changeStatement,
    changeRequiredBenefit,
    getBenefitsWithoutRelations,
    getModelQuestionnaireCompleted,
    getModelsBenefitGroupsRel,
    setModelQuestionnaireCompleted
} from '@api/benefiits';
import {getQuestions} from '@api/questionnaire';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import {
    LAYOUT_CARD,
    LAYOUT_LINE,
    REL_ITEM_TYPE_MODEL
} from '@page/QuestionsBenefits/config';
import BenefitCards from '@page/QuestionsBenefits/components/benefitCards';
import FilterPane from '@page/QuestionsBenefits/components/filterPane';
import FormBenefitRelations from './components/formBenefitRelations';
import FormModelAnswerStatement from './components/formModelAnswerStatement';
import Panes from './components/panes';
import ModelsCompleted from './components/modelsCompleted';

export default {
    name: 'QuestionsBenefits',
    components: {
        BenefitCards,
        Breadcrumbs,
        FilterPane,
        FormBenefitRelations,
        FormModelAnswerStatement,
        FormButton,
        FormCheckbox,
        PageHeader,
        PageTitle,
        Panes,
        ModelsCompleted,
    },
    props: {
        id: {
            type: String,
            default() {
                return '';
            },
        },
    },
    data() {
        return {
            questions: [],
            models: [],
            selectModels: [],
            selectedModelsInitial: [],
            selectedModelsKey: 1,
            modelsCompleted: [],
            modelName: '',
            answerDescription: '',
            question: '',
            formBenefitRelationsVisible: false,
            formAnswerToModelStatementVisible: false,
            editedAnswerId: null,
            editedModel: null,
            editedBenefits: null,
            editedRelations: null,
            editedHasIncompleteRelations: false,
            benefitsWithoutRelations: [],
            benefitsNotRequiresRelations: [],

            editedExtraStatement: {
                model: null,
                answer: null,
            },
        };
    },
    computed: {
        modelsFiltered() {
            let filtered;
            let selectModelsArr = [];

            if (this.$refs.filterPane?.selectModels) {
                selectModelsArr = Object.values(this.$refs.filterPane?.selectModels);
            }

            if (this.$refs.filterPane?.modelsWithoutRelations === 1) {
                filtered = this.models.filter((item) => !item.model_benefit_group_rels.length);
            } else {
                filtered = this.models;
            }

            if (selectModelsArr.length > 0) {
                filtered = filtered.filter((item) => selectModelsArr.includes(item.id));
            }

            return filtered;
        },
        benefitsWithoutRelationsFiltered() {
            const filtered = {};
            let selectModelsArr = [];

            if (this.$refs.filterPane?.selectModels) {
                selectModelsArr = Object.values(this.$refs.filterPane?.selectModels);
            }

            if (selectModelsArr.length > 0) {
                Object.entries(this.benefitsWithoutRelations).forEach(([key, value]) => {
                    if (selectModelsArr.includes(+key)) {
                        filtered[value?.modelName] = value?.benefitGroups;
                    }
                });
            } else {
                Object.entries(this.benefitsWithoutRelations).forEach(([key, value]) => {
                        filtered[value?.modelName] = value?.benefitGroups;
                });
            }

            return filtered;
        },
        questionnaireId() {
            return this.$route.params.id;
        },
        layouts() {
            return {LAYOUT_CARD, LAYOUT_LINE};
        }
    },
    watch: {
        modelsFiltered() {
            this.getModelsCompleted(this.questionnaireId);
        },
        // modelsCompleted: {
        //     handler() {
        //         this.saveModelsCompleted();
        //     },
        //     deep: true,
        //     immediate: false,
        // },
    },
    created() {
        this.getQuestionsAnswersWithBenefits(this.questionnaireId);
        this.getBenefitsWithoutRelations(this.questionnaireId);

        this.selectedModelsInitial = this.$route.query?.models || [];

        if (!Array.isArray(this.selectedModelsInitial)) {
            this.selectedModelsInitial = [this.selectedModelsInitial];
        }
        this.selectedModelsInitial = this.selectedModelsInitial.map((id) => +id);
    },
    methods: {
        async toggleCompleted(modelId) {
            await setModelQuestionnaireCompleted(
                this.questionnaireId,
                modelId,
                this.modelsCompleted.filter(
                    ({id, completed}) => id === modelId && completed === 1
                ).length
            );
        },
        async saveStatement(id, statement) {
            await changeStatement(id, statement);
        },
        changeRequired(id) {
            changeRequiredBenefit(id);
            this.getBenefitsWithoutRelations(this.questionnaireId);
        },
        async successSaveHandler() {
            this.models = await getModelsBenefitGroupsRel(this.questionnaireId);
        },
        async successExtraStatementSaveHandler() {
            this.questions = await getQuestions(this.questionnaireId);
        },
        async getQuestionsAnswersWithBenefits(questionnaireId) {
            this.questions = await getQuestions(questionnaireId);
            this.models = await getModelsBenefitGroupsRel(questionnaireId);
        },
        async getBenefitsWithoutRelations(questionnaireId) {
            this.benefitsWithoutRelations = await getBenefitsWithoutRelations(questionnaireId);
        },
        async getModelsCompleted(questionnaireId) {
            await getModelQuestionnaireCompleted(
                questionnaireId,
                (models) => {
                    this.modelsCompleted = this.modelsFiltered.map(({id, name}) => ({
                        id,
                        name,
                        completed: +models?.includes(id)
                    }));

                    // Обновляем ключ мультиселекта, чтобы обновить компонент и отобразить начальные значения
                    this.selectedModelsKey += Math.random();
                }
            );
        },
        // async saveModelsCompleted() {
        //     await setModelQuestionnaireCompleted(
        //         this.questionnaireId,
        //         this.modelsCompleted.filter(({completed}) => completed === 1).map(({id}) => id)
        //     );
        // },
        editRelationHandler(event, answer, model, question) {
            this.question = question.description;
            this.editedModel = model;
            this.answerDescription = answer.description;

            this.editedAnswerId = answer.id;
            this.editedBenefits = model.model_benefit_group_rels.map((el) => (
                {
                    value: el.item_id,
                    title: el.benefit_group.title,
                    data: {
                        benefit_title: el.benefit_title[0],
                        benefit_content: el.benefit_content[0],
                    },
                    // отмечаем группы выгод связанные с вопросом
                    mark: model.question_benefit_group_rels[question.id]
                        ? model.question_benefit_group_rels[question.id].includes(el.benefit_group.id)
                        : false,
                }
            ));
            this.editedHasIncompleteRelations = model.question_model_benefit_group_rels.some(
                    ({question_id, item_type}) => item_type === REL_ITEM_TYPE_MODEL && question_id === answer.id
            );
            this.editedRelations = model.question_model_benefit_group_rels
                .filter(({question_id, item_type}) => question_id === answer.id && item_type !== REL_ITEM_TYPE_MODEL);

            this.formBenefitRelationsVisible = true;
        },
        hideFormBenefitRelations() {
            this.formBenefitRelationsVisible = false;
        },
        editAnswerToModelStatement(event, answer, model) {
            this.editedExtraStatement = {
                model: model,
                answer: answer,
            };

            this.formAnswerToModelStatementVisible = true;
        },
        hideFormAnswerToModelStatement() {
            this.formAnswerToModelStatementVisible = false;
        }
    }
};
</script>
