<template src="../templates/benefitCard.html"></template>

<script>
import VueTippy from 'vue-tippy';
import FormButton from '@f/Button';
import {LAYOUT_CARD, LAYOUT_LINE} from '../config';
import '../../../layouts/default/styles/tippy.scss';

export default {
    name: 'BenefitCard',
    components: {
        FormButton,
        VueTippy
    },
    props: {
        benefitTitle: {
            type: String,
            default() {
                return '';
            },
        },
        benefitContent: {
            type: String,
            default() {
                return '';
            },
        },
        id: {
            type: [String, Number],
            default() {
                return '';
            },
        },
        layout: {
            type: String,
            default() {
                return LAYOUT_CARD;
            },
        },
        title: {
            type: String,
            default() {
                return '';
            },
        },
        content: {
            type: String,
            default() {
                return '';
            },
        },
        required: {
            type: [String, Number],
            default() {
                return '1';
            },
        },
        changeRequired: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            showTooltip: false,
        };
    },
    computed: {
        layouts() {
            return {LAYOUT_CARD, LAYOUT_LINE};
        }
    },
};
</script>
