<template src="../templates/formModelAnswerStatement.html"></template>

<script>
import FormButton from '@f/Button';
import FormInput from '@f/Input';
import Modal from '@c/Modal';
import {setAnswerModelStatement} from '@api/benefiits';

export default {
    name: 'FormModelAnswerStatement',
    components: {
        FormButton,
        FormInput,
        Modal,
    },
    props: {
        modalVisible: {
            type: Boolean,
            default() {
                return null;
            },
        },
        hideModal: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
        successHandler: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
        model: {
            type: Object,
            default() {
                return {};
            },
        },
        answer: {
            type: Object,
            default() {
                return {};
            },
        },
        questionnaireId: {
            type: [String, Number],
            default() {
                return null;
            },
        },
    },
    data() {
        return {
            statement: '',
        };
    },
    watch: {
        modalVisible(value) {
            if (value) {
                this.statement = this.answer?.statement_extra
                    ?.find((i) => (
                        i.questionnaire_id === +this.questionnaireId
                        && i.entity_id === +this.model.id
                        && i.entity_type === 'MODEL'
                    ))?.field_value;
            }
        },
    },
    methods: {
        async saveExtraStatement() {
            await setAnswerModelStatement(
                this.answer.id,
                this.model.id,
                this.statement,
                this.questionnaireId
            );

            this.successHandler();
            this.hideModal();
        },
    },
};
</script>
