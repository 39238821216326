<div
    class="row mt-3 flex-direction-column"
>
    <Pane
        v-for="(question, index) in questions"
        :key="index"
        :question="question"
        :models="models"
        :editRelationHandler="editRelationHandler"
        :editAnswerToModelStatement="editAnswerToModelStatement"
        :saveStatement="saveStatement"
    />
</div>
