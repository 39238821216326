<template src="../templates/benefitCards.html"></template>

<script>
import BenefitCard from './benefitCard';

import {LAYOUT_CARD, LAYOUT_LINE} from '../config';

export default {
    name: 'BenefitCards',
    components: {
        BenefitCard,
    },
    props: {
        layout: {
            type: String,
            default() {
                return LAYOUT_CARD;
            },
        },
        title: {
            type: String,
            default() {
                return '';
            },
        },
        benefits: {
            type: Object,
            default() {
                return {};
            },
        },
        changeRequired: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    computed: {
        layouts() {
            return {LAYOUT_CARD, LAYOUT_LINE};
        }
    },
};
</script>
