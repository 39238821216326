<div
    class="questionnaire-pane"
    :style="question.question_code.length > 0 ? '' : 'border: 1px solid red;'"
>
    <input :id="question.id" type="checkbox" class="pane-min-table hide">

    <div class="content p-0">
        <div
            class="questionnaire-editing-table"
            v-if="question.answers && models"
        >
            <table>
                <col>
                <col
                    v-if="question.answers.length > 10"
                    :span="question.answers.length"
                    width="200"
                >
                <thead class="header">
                <tr>
                    <td width="200"></td>
                </tr>
                <tr>
                    <td :colspan="question.answers.length + 1" class="sticky">
                        <div class="question-label">
                            <div class="models-count">
                                <span>Связано: {{ relatedModelsCount }} из {{ models.length }}</span>
                            </div>
                            <label class="question" :for="question.id">
                                <span class="icon expand"></span>
                                <span class="title h5">{{ question.description }}</span>
                            </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="response-statement-wrap sticky" width="200">
                        <div class="top">Ответ</div>
                        <div>Утверждение</div>
                    </th>
                    <th v-for="(answer, index) in question.answers" :key="index" class="sticky">
                        <div class="answer-question-wrap">
                            <div class="h6">{{ answer.description }}</div>
                            <FormInput
                                v-if="statementEditor === answer.id"
                                v-model="answer.statement"
                                @focusout="
                                saveStatement(answer.id, answer.statement);
                                openStatementEditor(false)"
                            />

                            <div @click="openStatementEditor(answer.id)" v-else>{{ answer.statement }}</div>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(model) in models" :key="model.id">
                    <th width="200">
                        <div :class="`row-group w100 justify-content-between ${(!model.question_model_benefit_group_rels?.length && ' bg-red')}`">
                            <span class="h6 model-title">
                                 <a target="_blank" :href="getAdminModelLink(model)"> {{model.name}}</a>
                            </span>
                            <span
                                class="icon alert mr-0"
                                v-if="modelBenefitsRelatedTitles[model.id]?.length"
                                v-tippy="{content: modelBenefitsRelatedTitles[model.id]}"
                            ></span>
                        </div>
                    </th>
                    <td
                        v-if="!model.model_benefit_group_rels?.length"
                        :colspan="question.answers.length"
                    >
                        <div class="row-group w100 bg-red text-red">
                            Отсутствуют выгоды по модели
                        </div>
                    </td>
                    <td
                        v-else
                        v-for="(answer, index) in question.answers"
                        :key="answer.id"
                    >
                        <span
                            v-if="model.question_model_benefit_group_rels.filter(({question_id, item_type}) => question_id === answer.id && item_type === relType.REL_ITEM_TYPE_MODEL).length > 0"
                            @click="editRelationHandler($event, answer, model, question)"
                            style="cursor: pointer;"
                        >Добавить выгоду на страницу</span>
                        <div
                            class="add-relation-button"
                            v-else-if="model.question_model_benefit_group_rels.filter(({question_id, item_type}) => question_id === answer.id && item_type !== relType.REL_ITEM_TYPE_MODEL).length > 0"
                        >
                            <FormButton
                                :attributes="{
                                    'class': ['btn-sm', 'btn-green', 'w100', 'inner-w100'],
                                    'data-modal': 1,
                                    'data-modal-content': 'questionnaire/entity-edit'
                                }"
                                @click="editRelationHandler($event, answer, model, question)"
                            >
                                <template v-slot:left>
                                    <span class='w100 text-left'>
                                        🗸&nbsp;
                                        {{model.question_model_benefit_group_rels.filter(({question_id, item_type}) => question_id === answer.id && item_type !== relType.REL_ITEM_TYPE_MODEL).length}}
                                    </span>
                                </template>
                                <template v-slot:center>
                                    <tippy
                                        :content="getBenefitsTextForModel(model, answer)"
                                        allowHTML
                                        arrow
                                        theme="big"
                                    >
                                        <span class="icon alert mr-0 text-center"></span>
                                    </tippy>
                                </template>
                                <template
                                    v-slot:right
                                >
                                    <tippy
                                        v-if="getExtraStatement(model, answer, questionnaireId)"
                                        :content="getExtraStatement(model, answer, questionnaireId)"
                                        allowHTML
                                        arrow
                                        theme="big"
                                    >
                                        <span
                                            class="icon edit-edited mr-0"
                                            @click.stop="editAnswerToModelStatement($event, answer, model)"
                                        ></span>
                                    </tippy>
                                    <span
                                        v-else
                                        class="icon edit mr-0"
                                        @click.stop="editAnswerToModelStatement($event, answer, model)"
                                    ></span>
                                </template>
                            </FormButton>
                        </div>
                        <FormButton
                            v-else
                            label="Добавить связь"
                            :attributes="{
                                'class': ['btn-sm', 'btn-blue', 'w100', 'show-on-hover'],
                                'data-modal': 1,
                                'data-modal-content': 'questionnaire/entity-edit'
                            }"
                            @click="editRelationHandler($event, answer, model, question)"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
