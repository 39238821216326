import api from '../tools/api';

/**
 * Сохранить связь Ответа (Вопроса) со связями Модель=>ГруппаВыгод
 * @param id
 * @param relations
 * @param incomplete
 * @returns {Promise | Promise<unknown>}
 */
export const setQuestionToModelBenefitGroupRel = (id, relations, incomplete) => api.request(
    'benefits',
    'set-question-to-model-benefit-group-rel',
    {id, relations, incomplete}
);

/**
 * Получить список ГруппВыгод по id связей с Моделями
 * @param relIds
 * @returns {Promise | Promise<unknown>}
 */
export const getBenefitGroupsByModelRel = (relIds) => api.request(
 'benefits',
 'get-benefit-group-by-model-rel',
 {relIds}
);

/**
 * Получить список Моделей со связями с ГруппамиВыгод
 * @param questionnaireId
 * @returns {Promise | Promise<unknown>}
 */
export const getModelsBenefitGroupsRel = (questionnaireId) => api.request(
 'benefits',
 'get-models-benefit-groups-rel',
 {questionnaireId}
);

/**
 * Список ГруппВыгод, которые в рамках Опросника не имеют связей с Моделью-Ответом
 * @param questionnaireId
 * @returns {Promise | Promise<unknown>}
 */
export const getBenefitsWithoutRelations = (questionnaireId) => api.request(
 'benefits',
 'get-benefit-groups-without-relations',
 {questionnaireId}
);

/**
 * @deprecated
 * @param questionnaireId
 * @returns {Promise | Promise<unknown>}
 */
export const getBenefitsNotRequireRelations = (questionnaireId) => api.request(
 'benefits',
 'get-benefit-not-require-relations',
 {questionnaireId}
);

/**
 * Помечаем, что Модель в рамках Опросника имеет все связи с Ответами
 * @param questionnaireId
 * @param modelIds
 * @param completed
 * @returns {Promise | Promise<unknown>}
 */
export const setModelQuestionnaireCompleted = (questionnaireId, modelIds, completed) => api.request(
 'benefits',
 'set-models-questionnaire-completed',
 {questionnaireId, modelIds, completed}
);

/**
 * Получаем список Моделей, для которых в рамках Опросника настроены связи с Ответами
 * @param questionnaireId
 * @returns {Promise | Promise<unknown>}
 */
export const getModelQuestionnaireCompleted = (
    questionnaireId,
    successHandler = (e) => {},
    errorHandler = (e) => {}
) => api.request(
 'benefits',
 'get-models-questionnaire-completed',
 {questionnaireId}
).then(successHandler).catch(errorHandler);

export const changeRequiredBenefit = (id) => api.request(
    'benefits',
    'change-required-benefit',
    {id}
);

export const changeStatement = (id, statement) => api.request(
    'benefits',
    'change-statement',
    {id, statement}
);

export const setAnswerModelStatement = (answerId, modelId, statement, questionnaireId) => api.request(
    'benefits',
    'set-answer-to-model-statement',
    {
        answerId,
        modelId,
        statement,
        questionnaireId
    }
);



