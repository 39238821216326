<div
    class="row my-5 flex-direction-column"
    v-if="layout===layouts.LAYOUT_CARD"
>
    <h3 class="h1">{{title}}</h3>
    <div
        class="row-col d-flex mt-3"
        v-for="(benefitsList, modelName) in benefits"
        :key="modelName"
    >
        <div
            class="row mt-3 card"
        >
            <div class="card-body">
                <h5 class="card-title">{{modelName}}</h5>
                <div class="row no-relation">
                    <BenefitCard
                        :layout="layout"
                        v-for="(benefit, index) in benefitsList"
                        :key="benefit.id"
                        :id="benefit.id"
                        :title="benefit.title"
                        :content="benefit.description"
                        :benefitTitle="benefit.benefit_title[0]"
                        :benefitContent="benefit.benefit_content[0]"
                        :changeRequired="changeRequired"
                        :required="benefit.requires_question_relation"
                    />
                </div>
            </div>

        </div>
    </div>
</div>

<div
    class="row my-5"
    v-if="layout===layouts.LAYOUT_LINE"
>
    <h3 class="h1 mb-3">{{title}}</h3>
    <BenefitCard
        :layout="layout"
        v-for="(benefit, index) in benefits"
        :key="benefit.id"
        :title="benefit.title"
        :content="benefit.description"
    />
</div>
