<div
    class="row mt-3 card"
>
    <div class="card-body d-flex">
        <h5 class="card-title col-12">Модели, для которых настроены связи (отмечаем вручную)</h5>
        <FormCheckbox
            v-for="(model) in models"
            :key="model.id"
            :label="model.name"
            v-model="model.completed"
            @change="toggleCompleted(model.id)"
            wrapperClass="col-4"
        />
    </div>

</div>
