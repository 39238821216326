<div class="w-100">
    <div class="row d-flex align-items-center no-gap">
        <div class="col-4">
            <FormButton
                :label="`<span class='icon filter xs'></span> Параметры подбора`"
                :attributes="{
                'class': ['btn-sm', 'btn-blue', 'btn', 'mr-2'],
            }"
            />
            <FormButton
                :label="`Сбросить`"
                :attributes="{
                'class': ['btn-sm', 'btn-white', 'btn'],
            }"
            />
        </div>
        <div class="col-auto">
            <FormCheckbox
                label="Какой-то быстрый фильтр"
                wrapperClass="inline"
            />
            <FormCheckbox
                v-model="modelsWithoutRelations"
                label="Модель без связей"
                wrapperClass="inline"
            />
            <FormCheckbox
                label="Показать УТП"
                wrapperClass="inline"
            />
        </div>
    </div>
    <div class="row d-flex align-items-center no-gap">
        <div class="col-12 model-select">
            <Multiselect
                :key="selectedModelsKey"
                mode="tags"
                v-model="selectModels"
                placeholder="Выбери модели"
                label="name"
                trackBy="name"
                valueProp="id"
                no-options-text="Введите текст"
                no-results-text="Нет вариантов для выбора"
                :filterResults="false"
                :minChars="2"
                :resolveOnLoad="false"
                :delay="300"
                :searchable="true"
                :options='models'
                :createTag="true"
                :hideSelected="true"
            />
        </div>
    </div>
</div>
