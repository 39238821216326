<Modal
    v-if="modalVisible"
    @onClose="hideModal"
    :close-button="true"
>
    <template v-slot:body>

        <div class="modal-header px-2 py-3">
            <h4><span class="icon filter xs mr-1"></span>Текст выгоды в оферте для модели {{model.name}}</h4>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="entity-row-group">
                    <div class="col-4">
                        <FormInput
                            v-model="statement"
                            :label="answer.description"
                        />
                    </div>
                </div>
                <div class="row-col py-3">
                    <div class="col-12">
                        <FormButton
                            :label="`<span class=''></span>Применить`"
                            :attributes="{
                                'class': ['btn-green', 'mr-1']
                            }"
                            @click="saveExtraStatement"
                        />
                        <FormButton
                            label="Отмена"
                            :attributes="{
                                'class': ['btn-white']
                            }"
                            @click="hideModal"
                        />
                    </div>
                </div>
            </div>
        </div>
    </template>
</Modal>
