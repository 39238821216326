<Modal
    v-if="modalVisible"
    @onClose="hideModal"
    :close-button="true"
>
    <template v-slot:body>

        <div class="modal-header px-2 py-3">
            <h4><span class="icon filter xs mr-1"></span>{{question}}</h4>
        </div>
        <div class="modal-body">
            <div class="container">
                <div class="info">
                    <p>Модель: {{model.name}}</p>
                    <p>Ответ: {{answerDescription}}</p>
                </div>
                <div class="entity-row-group">
                    <div
                        class="row-col py-3 align-items-center"
                        v-for="(relation) in filteredRelations"
                        :key="relation.index"
                    >
                        <div class="col-4">
                            <FormSelect
                                v-model="relation.item_id"
                                :options="benefits"
                                group-class="select"
                            />
                        </div>
                        <div class="col-2">
                            <FormSelect
                                v-model="relation.positive"
                                :options="relationPositivityOptions"
                                group-class="select"
                            />
                        </div>
                        <div class="col-2 text-right">
                            <FormButton
                                :label="`<span class='icon delete m-0'></span>`"
                                :attributes="{
                                    'class': ['btn-sm', 'btn-red', 'p-1', 'no-bg']
                                }"
                                @click="removeRelation(relation.index)"
                            />
                        </div>

                        <div v-for="benefit in benefits">
                            <div v-if="+benefit.value === +relation.item_id">
                                <h6 style="padding: 15px 0 0 15px" v-html="benefit.data.benefit_title"></h6>
                                <p style="padding: 5px 0 0 15px" v-html="benefit.data.benefit_content"></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row-col pt-3">
                    <div class="col-12">
                        <FormButton
                            label="Добавить еще параметр"
                            :attributes="{
                                'class': ['btn-white', 'text-blue']
                            }"
                            @click="addRelation"
                        />
                    </div>
                </div>
                <div class="row-col py-3">
                    <div class="col-12">
                        <FormButton
                            :label="`<span class=''></span>Применить`"
                            :attributes="{
                                'class': ['btn-green', 'mr-1']
                            }"
                            @click="saveBenefitRelations"
                        />
                        <FormButton
                            label="Отмена"
                            :attributes="{
                                'class': ['btn-white']
                            }"
                            @click="hideModal"
                        />
                        <FormCheckbox
                            label="Нет выгоды на странице"
                            v-model="incompleteRel"
                        />
                    </div>
                </div>
            </div>
        </div>
    </template>
</Modal>
