<PageHeader>
    <div class="row c">
        <Breadcrumbs :items="[{title:'Вопросы-выгоды'}]"/>
    </div>
</PageHeader>

<PageTitle title="Вопросы-выгоды"/>


<div class="row c mt-4 flex-direction-column">
    <FilterPane
        ref="filterPane"
        :models="models"
        :selectedModelsInitial="selectedModelsInitial"
        :selectedModelsKey="selectedModelsKey"
    />

    <ModelsCompleted
        :models="modelsCompleted"
        :toggleCompleted="toggleCompleted"
    />

    <Panes
        :questions="questions"
        :models="modelsFiltered"
        :editRelationHandler="editRelationHandler"
        :editAnswerToModelStatement="editAnswerToModelStatement"
        :saveStatement="saveStatement"
    />

    <BenefitCards
        title="Выгоды не привязанные к вопросу"
        :layout="layouts.LAYOUT_CARD"
        :benefits="benefitsWithoutRelationsFiltered"
        :changeRequired="changeRequired"
    />
</div>


<FormBenefitRelations
    :question-id="editedAnswerId"
    :benefits="editedBenefits"
    :relations="editedRelations"
    :modalVisible="formBenefitRelationsVisible"
    :hideModal="hideFormBenefitRelations"
    :successHandler="successSaveHandler"
    :model="editedModel"
    :answerDescription="answerDescription"
    :question="question"
    :hasIncompleteRelations="editedHasIncompleteRelations"
/>

<FormModelAnswerStatement
    :questionnaireId="questionnaireId"
    :modalVisible="formAnswerToModelStatementVisible"
    :hideModal="hideFormAnswerToModelStatement"
    :successHandler="successExtraStatementSaveHandler"
    :model="editedExtraStatement.model"
    :answer="editedExtraStatement.answer"
/>
