<template src="../templates/panes.html"></template>

<script>
import Pane from '@page/QuestionsBenefits/components/pane';

export default {
    name: 'Panes',
    components: {
        Pane,
    },
    props: {
        questions: {
            type: Array,
            default() {
                return [];
            },
        },
        models: {
            type: Array,
            default() {
                return [];
            },
        },
        editRelationHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        editAnswerToModelStatement: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        saveStatement: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
};
</script>
