<div
    class="col"
    v-if="layout===layouts.LAYOUT_CARD"
>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">{{title}}</h5>
            <p class="card-text">{{content}}</p>
            <p>
                {{ benefitTitle }}
                <tippy :content="benefitContent" allowHTML arrow theme="big">
                    <span class="icon alert mr-0"></span>
                </tippy>
            </p>
            <FormButton
                label="Не требуется"
                :attributes="{
                'class': required ? ['btn-sm', 'no-bg', 'btn-red'] : ['btn-sm', 'btn-red'],
                }"
                @click="changeRequired(id)"
            />
        </div>
    </div>
</div>

<div
    class="row d-flex flex-nowrap mb-1"
    v-if="layout===layouts.LAYOUT_LINE"
>
    <FormButton
        :label="title"
        :attributes="{
            'class': ['btn-sm', 'btn-white', 'justify-content-start', 'w100'],
        }"
        @click="onBind"
    >
        <template v-slot:left>
            <tippy
                content="<p class='text-center'> Текст выгод:<br>Дополнительная увязка<br>не требуется, достаточно<br>увязки в шпагат</p>"
                allowHTML
                arrow
                theme="big"
            >
                <span class="icon alert mr-0"></span>
            </tippy>
        </template>

    </FormButton>
</div>
