<template src="../templates/modelsCompleted.html"></template>

<script>
import Pane from '@page/QuestionsBenefits/components/pane';
import FormCheckbox from '@f/Check';

export default {
    name: 'ModelsCompleted',
    components: {
        Pane,
        FormCheckbox,
    },
    props: {
        models: {
            type: Array,
            default() {
                return [];
            },
        },
        toggleCompleted: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
    },
};
</script>
