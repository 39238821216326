<template src="../templates/formBenefitRelations.html"></template>

<script>
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import FormCheckbox from '@f/Check';
import Modal from '@c/Modal';
import {setQuestionToModelBenefitGroupRel} from '@api/benefiits';
import {REL_ITEM_TYPE_MODEL_BENEFIT_GROUP_REL, REL_ITEM_TYPE_MODEL} from '../config';

export default {
    name: 'FormBenefitRelations',
    components: {
        FormCheckbox,
        FormSelect,
        FormButton,
        Modal,
    },
    props: {
        questionId: {
            type: Number,
            default() {
                return null;
            },
        },
        benefits: {
            type: Array,
            default() {
                return [];
            },
        },
        relations: {
            type: Array,
            default() {
                return [];
            },
        },
        modalVisible: {
            type: Boolean,
            default() {
                return null;
            },
        },
        hideModal: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
        successHandler: {
            type: Function,
            default() {
                return Function.prototype;
            },
        },
        model: {
            type: Object,
            default() {
                return {};
            },
        },
        answerDescription: {
            type: String,
            default() {
                return '';
            },
        },
        hasIncompleteRelations: {
            type: Boolean,
            default() {
                return false;
            },
        },
        question: {
            type: String,
            default() {
                return '';
            },
        },
    },
    data() {
        return {
            incompleteRel: 0,
        };

    },
    computed: {
        rels() {
            return this.relations;
        },
        filteredRelations() {
            // добавим оригинальные индексы, пригодится для удаления и key
            this.rels.forEach((el, index) => { this.rels[index].index = index; });

            return this.rels.filter((el) => (typeof el.deleted === 'undefined'));
        },
        relationPositivityOptions() {
            return [{title: '+', value: 1}, {title: '-', value: 0}];
        }
    },
    updated() {
        this.incompleteRel = +this.hasIncompleteRelations;
    },
    methods: {
        async saveBenefitRelations() {
            await setQuestionToModelBenefitGroupRel(
                this.questionId,
                // сохраняем заполненые неудаленные связи либо удаленные связи с id
                this.rels.filter(
                    (el) => (
                        (typeof el.deleted !== 'undefined' && typeof el.id !== 'undefined')
                        || typeof el.deleted === 'undefined'
                    )
                    && el.item_id !== null
                ),
                // связь с моделью, но без группы выгоды
    {
                    question_id: this.questionId,
                    item_type: REL_ITEM_TYPE_MODEL,
                    item_id: this.model.id,
                    deleted: this.incompleteRel ? 0 : 1
                }
            );
            this.successHandler();
            this.hideModal();
        },
        addRelation() {
            this.rels.push({
                item_type: REL_ITEM_TYPE_MODEL_BENEFIT_GROUP_REL,
                item_id: null,
                positive: 1,
            });
        },
        removeRelation(index) {
            this.rels[index].deleted = 1;
        },
    },
};
</script>
