<template src="../templates/pane.html"></template>

<script>
import FormButton from '@f/Button';
import FormInput from '@f/Input';
import {REL_ITEM_TYPE_MODEL_BENEFIT_GROUP_REL, REL_ITEM_TYPE_MODEL} from '../config';

export default {
    name: 'Pane',
    components: {
        FormButton,
        FormInput
    },
    props: {
        question: {
            type: Object,
            default() {
                return null;
            },
        },
        models: {
            type: Array,
            default() {
                return [];
            },
        },
        editRelationHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        editAnswerToModelStatement: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        saveStatement: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            statementEditor: false,
        };
    },
    computed: {
        questionnaireId() {
            return this.$route.params.id;
        },
        relType() {
            return {REL_ITEM_TYPE_MODEL_BENEFIT_GROUP_REL, REL_ITEM_TYPE_MODEL};
        },
        relatedModelsCount() {
            return this.models.reduce(
                (acc, model) => {
                    // Пересечение массива id ответов из вопроса с массивом id ответов из модели
                    const intersection = model.question_model_benefit_group_rels
                        .map(({question_id, item_type}) => ({question_id, item_type}))
                        .filter(
                            ({question_id, item_type}) => this.question.answers
                                .map(({id}) => id)
                                .includes(question_id)
                            && item_type === REL_ITEM_TYPE_MODEL_BENEFIT_GROUP_REL
                        );

                    if (intersection.length > 0) {
                        return acc + 1;
                    }
                    return acc;
                },
                0
            );
        },
        modelBenefitsRelatedTitles() {
            return this.models.reduce(
                (acc, el) => {
                    const {id} = el;
                    const modelBenefitsGroupRelsRelatedWithAnswers = el.question_model_benefit_group_rels.map(
                            ({item_id}) => item_id
                    );
                    acc[id] = el.model_benefit_group_rels.reduce((titleAcc, rel) => {
                        if (rel !== null && modelBenefitsGroupRelsRelatedWithAnswers.includes(rel.item_id)) {
                            return `${titleAcc} ${rel.benefit_group.title}`;
                        }
                        return titleAcc;
                    }, '');

                    return acc;
                }, {}
            );
        },
    },
    mounted() {
        const els = document.querySelectorAll('.questionnaire-pane table thead tr:nth-child(2)');
        setTimeout(() => {
            els.forEach((el) => {
                const height = el.offsetHeight;
                const nextEl = el.nextSibling;

                nextEl.childNodes.forEach((child) => {
                    if (child.nodeName === 'TH') {
                        child.style.top = `${height}px`;
                    }
                });
            });
        }, 0);

    },
    methods: {
        openStatementEditor(value) {
            this.statementEditor = value;
        },
        getAdminModelLink(model) {
            return `${process.env.VUE_APP_HOST_URL}/admin/models/update?id=${model.id}`;
        },
        getBenefitsTextForModel(model, answer) {
            const selectedBenefits = model.question_model_benefit_group_rels
                    .filter(({question_id}) => +question_id === +answer.id)
                    .map(({item_id}) => item_id);

            const selectedBenefitsArr = Object.values(selectedBenefits);

            let benefits = '<ul>';
            model.model_benefit_group_rels.forEach((item) => {

                if (selectedBenefitsArr.includes(item.item_id)) {
                    // benefits = `${benefits} <h6>${item.benefit_title}</h6>> ${item.benefit_content}`;
                    benefits = `${benefits}
                <li style="padding-bottom: 10px">
                <span>${item.benefit_title}</span>
                <span>${item.benefit_content}</span>
                </li>`;
                }

            });
            return `${benefits}</ul>`;
        },
        getExtraStatement(model, answer, questionnaireId) {
            return answer?.statement_extra
                ?.find((i) => (
                    i.questionnaire_id === +questionnaireId
                    && i.entity_id === +model.id
                    && i.entity_type === 'MODEL'
                ))?.field_value;
        },
    },
};
</script>
