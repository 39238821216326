<template src="../templates/filterPane.html"></template>

<script>
import FormButton from '@f/Button';
import FormCheckbox from '@f/Check';
import Multiselect from '@vueform/multiselect';

export default {
    name: 'FilterPane',
    components: {
        FormButton,
        FormCheckbox,
        Multiselect
    },
    props: {
        models: {
            type: Object,
            default() {
                return {};
            },
        },
        selectedModelsInitial: {
            type: Array,
            default() {
                return [];
            },
        },
        selectedModelsKey: {
            type: Number,
            default() {
                return 1;
            },
        }
    },
    data() {
        return {
            modelsWithoutRelations: 0,
            selectModels: this.selectedModelsInitial || [],
        };
    },
    watch: {
        selectModels: {
            immediate: false,
            handler(val, oldVal) {
                this.$router.push({query: {models: val}});
            }
        },

    },
};
</script>
